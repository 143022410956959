import * as React from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import SEO from "../components/seo";
import "../scss/custom.scss";
import OfficeDetail from "../components/OfficeDetail/OfficeDetail";

const OfficeTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.office;
    return (
        <div>
            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} location={props.location} image={GQLPage?.Image?.formats?.small?.url} />
            <OfficeDetail data={GQLPage} />
        </div>
    )
}

export default OfficeTemplate;

export const pageQuery = graphql`
    query OfficeQuery($id: ID!) {
        glstrapi {
            office(id: $id, publicationState: LIVE) {
                Address
                Latitude
                Longitude
                Meta_Description
                Meta_Title
                Mobile
                Name
                Sub_Title
                Title
                Longitude
                Latitude
                URL
                Video_URL 
                Sub_Title
                Description 
                Image {
                    url
                    alternativeText
                    formats
                  }
                id
                Office_Hours { 
                    Title 
                    Office_Timings {
                        Timing
                    }
                }
                team_contacts {
                    Designation
                    Phone
                    Name
                    offices{
                        Name
                    }
                    Tile_Image {
                      url
                      alternativeText
                    }
                }
                teams {
                    Designation
                    Phone
                    Name
                    offices{
                        Name
                    }
                    Tile_Image {
                      url
                      alternativeText
                    }
                }
                Area_Guide {
                    Area_Title
                    Area_Sub_Title
                    Embed_Video_URL
                    URL
                    Tile_Image {
                      alternativeText
                      url
                    }
                    Modules_Area_Guides {
                      ... on GLSTRAPI_ComponentModulesIntro {
                        id
                        Intro_Content
                      }
                    }
                }
                Modules {
                    ... on GLSTRAPI_ComponentModulesFooterBlock {
                        id
                        CTA_1_Label
                        CTA_2_Label
                        CTA_1_URL {
                        URL
                        id
                        }
                        CTA_2_URL {
                        URL
                        id
                        }
                        Title
                        type: __typename
                    }
                    ... on GLSTRAPI_ComponentModulesContentWithCollection {
                        CTA_Label
                        Title_With_Collection: Select_Collection
                        Place
                        Sub_Title
                        Title
                        _id
                        CTA_Link {
                            URL
                            id
                        }
                    }
                    ... on GLSTRAPI_ComponentModulesGoogleReviews {
                        id
                        Reviews_Background_Image {
                            alternativeText
                            url
                        }
                    }
                }
            }
        }
    }
`